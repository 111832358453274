<template>
  <div class="checklist-detail">
    <div class="absolute top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="px-4 md:px-10 mx-auto w-full">
      <div class="flex flex-wrap mt-4">
        <SlideUp>
          <Form
            :form="formData"
            formName="new checklist"
            lastPageName="CheckList"
          />
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import SlideUp from "@/components/transitions/SlideUp.vue";
import Form from "@/components/Form.vue";
export default {
  name: "NewCheckListDetail",
  components: { SlideUp, Form },
  data() {
    const formData = [
      {
        section: "Checklist Information",
        dispatch: "checkLists/createCheckList",
        fields: [
          { key: "id", name: "id", type: "hidden", value: "" },
          {
            key: "name",
            name: "title",
            type: "text",
            value: ""
          }
        ]
      }
    ];

    return {
      formData
    };
  }
};
</script>
